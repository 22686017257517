import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import Layout, { Wrapper } from '../layouts/Layout';
import styled from '../styled';
import { SEO } from '../components/SEO';
import { HidableBranding as Branding } from '../components/Branding';
import { Highlight } from '../components/Highlight';
import { ProductHuntBanner } from '../components/ProductHuntBanner';
import { SUBSCRIBER_COUNT } from '../constants';

const SPONSORED_COLOR = '#c1ae89';

const Email = styled(Paper)`
  background-color: white;
  color: black;
  padding: 60px 12px;
  margin: ${(p) => p.theme.spacing(5)}px 0;

  .ad {
    background-color: #fffbe6;
    padding: 12px 24px;
    max-width: 650px;
    margin: 0 auto;
  }

  p {
    u {
      color: #1890ff;
    }
  }
`;

const StyledA = styled('a')`
  border-bottom: 2px solid ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.primary.main};
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Sponsor"
        siteUrl="https://bloggingfordevs.com"
        description="Sponsor the newsletter that teaches developers about blogging and SEO."
        noIndex
        pathname="sponsor/"
      />
      <ProductHuntBanner />
      <div
        style={{
          padding: '16px',
          margin: '0 auto'
        }}
      >
        <Wrapper style={{ maxWidth: '650px' }}>
          <Branding to="/">Blogging for Devs</Branding>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            style={{ textAlign: 'center', marginBottom: '24px' }}
          >
            Sponsor
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            Thanks for your interest in sponsoring Blogging for Devs!
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            We have two sponsorship mediums (the newsletter and events). Below,
            you'll find the newsletter options.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            To discuss a sponsored event or a custom sponsorship package, please
            reach out by email at{' '}
            <StyledA href="mailto:hi@bloggingfordevs.com">
              hi@bloggingfordevs.com
            </StyledA>
            .
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            Please note that all sponsorships are subject to evaluation for fit
            and quality for our extended community of subscribers.
          </Typography>
          <br />
          <br />
          <Typography variant="h4" component="h2" paragraph>
            Blogging for Devs Newsletter
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            The Blogging for Devs Newsletter{' '}
            <Highlight>
              reaches over {SUBSCRIBER_COUNT} software developers
            </Highlight>{' '}
            who are building their online audience through blogging.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            Open rates at 45-50%, with a 6-10% click rate. It's sent 3x per
            month.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            I'm happy to work with you on copy that I believe will resonate with
            subscribers.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            Each edition features just one sponsor.
          </Typography>
          <br />
          <Typography variant="h5" component="h3" paragraph>
            <strong>About Blogging for Devs subscribers</strong>
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            I do not collect detailed analytics on subscribers, such as
            demographics, so the following is an approximation of subscriber
            make-up.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            <strong>By region:</strong> North America (33%), Europe (33%), Asia
            (18%), Africa (6%), South America (4.5%), Oceania (2.5%)
          </Typography>
          <img
            src="/images/subscriber-location.png"
            alt="Subscriber location"
            style={{
              display: 'block',
              marginBottom: '12px',
              borderRadius: '8px',
              maxWidth: '100%'
            }}
          />
          <Typography variant="h6" component="p" paragraph>
            Most subscribers are individual developers, with an emphasis on web
            development but also spanning the entire technical stack (DevOps,
            Mobile, Backend, and so forth).
          </Typography>
          <br />
          <Typography variant="h5" component="h3" paragraph>
            <strong>Newsletter Sponsorship - $175</strong>
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            Use up to 60 words to make your pitch. The sponsored link is placed
            inline with the email content, right before the main newsletter
            content. Here's an example of how it could look:
          </Typography>
          <Email>
            <div className="ad">
              <Typography
                variant="h6"
                component="p"
                paragraph
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: SPONSORED_COLOR
                }}
              >
                SPONSORED
              </Typography>
              <Typography
                variant="h6"
                component="p"
                paragraph
                style={{ padding: '12px 0', fontWeight: 'bold' }}
              >
                📈 Monitor your website portfolio and grow your revenue
              </Typography>
              <Typography
                variant="body1"
                component="p"
                paragraph
                style={{ lineHeight: '2rem' }}
              >
                Stop logging into dozens of networks trying to piece together
                how your affiliate websites are performing. <u>Affilimate</u>{' '}
                aggregates analytics from all your affiliate platforms, links,
                and websites in a single dashboard so you can save time and get
                insights to grow your revenue.{' '}
                <u>Click here to check it out.</u>
              </Typography>
            </div>
          </Email>
          <Typography variant="h5" component="h3" gutterBottom>
            <strong>Batch Newsletter Sponsorship Slots</strong>
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            Sponsorship rates for Blogging for Devs grow with the size of our
            readership. You can lock in today's rates by placing a batch order.
          </Typography>
          <Typography variant="h6" component="ul" paragraph>
            <li>1x Sponsored Link - $175</li>
            <li>2x Sponsored Link - $300</li>
            <li>3x Sponsored Link - $450</li>
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            Get in touch at{' '}
            <StyledA href="mailto:hi@bloggingfordevs.com">
              hi@bloggingfordevs.com
            </StyledA>{' '}
            to discuss a sponsorship and reserve the next available slot.
          </Typography>
        </Wrapper>
      </div>
    </Layout>
  );
};

export default IndexPage;
