import React from 'react';
import styled from '../styled';

const Wrapper = styled('a')`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 10px 12px 12px;
  color: #e6f7ff;
  text-align: center;
  font-weight: 700;
  background-color: #1890ff;

  &:hover {
    background-color: #40a9ff;
    color: #ffffff;

    .link {
      border-bottom: 2px solid #ffffff;
    }
  }

  .link {
    border-bottom: 2px solid #e6f7ff;
  }
`;

export const ProductHuntBanner = () => null;
/*
(
  <Wrapper
    href="https://www.producthunt.com/posts/blogging-for-devs"
    target="_blank"
    title="Open Product Hunt in a New Tab"
    rel="nofollow noopener"
  >
    <span role="img">🎉</span>{' '}
    <span className="link">We're on Product Hunt right now!</span> &nbsp;
    <span role="img">🎉</span>
  </Wrapper>
);
*/
